<template>
  <div class="container is-fluid">
    <page-header :entity="$EntitiesName.Import">
      <h1 class="title">Import des recettes</h1>
      <template #actions>
        <button
          class="button is-warning"
          :disabled="loading || hasError"
          @click="importRevenues"
        >
          Importer
        </button>
      </template>
    </page-header>

    <div class="notification is-success" v-if="importSuccess">
      <button class="delete" @click="importSuccess = false"></button>
      L'import s'est déroulé avec succès !
    </div>

    <div
      class="block has-background-warning-light error-message"
      v-if="importErrors"
    >
      <p class="title is-6">
        Les lignes suivantes n'ont pas pu être importées :
      </p>
      <table
        class="table errors-table has-background-warning-light"
        style="width: 100%"
      >
        <thead>
        <tr>
          <th class="has-text-left">Ligne</th>
          <th class="has-text-left">Raison</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(reason, line) in notImported" :key="'ni_' + line">
          <td>{{ Number(line) + 1 }}</td>
          <td>{{ reason }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div
      class="block has-background-warning-light error-message"
      v-if="hasError"
    >
      <p class="title is-6">
        Les lignes suivantes contiennent des erreurs :
      </p>
      <table
        class="table errors-table has-background-warning-light"
        style="width: 100%"
      >
        <thead>
        <tr>
          <th class="has-text-left">Ligne</th>
          <th class="has-text-left">Raison</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(error, line) in lineErrors" :key="'err_' + line">
          <td>{{ Number(error.row) + 1 }}</td>
          <td>{{ error.message }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <OrdersImport
      ref="spreadsheet"
      :columns="handsonColumns"
      v-model="importData"
      @valid="valid = $event"
      @errors="showErrors"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrdersImport from "@/views/imports/Orders.vue";
import axios from "axios";

export default {
  name: "revenues-import",
  components: { OrdersImport },
  data: function () {
    return {
      notImported: {},
      importSuccess: false,
      importData: [],
      valid: true,
      lineErrors: [],
      hasError: false,
      handsonColumns: [
        {
          label: "Catégorie",
          key: "category",
          required: true,
        },
        {
          label: "N° BDC",
          key: "orderNumber",
          required: true,
        },
        {
          label: "Imputation",
          key: "eotp",
          required: true
        },
        {
          label: "Montant",
          type: 'numeric',
          key: "amount",
          required: true
        },
        {
          label: "Facturé",
          type: 'numeric',
          key: "invoiced",
          required: true
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      loading: "App/isLoading",
    }),
    importErrors() {
      return Object.keys(this.notImported).length > 0;
    },
  },
  methods: {
    importRevenues() {
      this.importSuccess = false;
      this.notImported = {};
      const importData = this.importData
        .filter(
          (row) =>
            row.category ||
            row.eotp ||
            row.orderNumber ||
            row.amount ||
            row.invoiced
        )
        .map((row, idx) => {
          row.index = idx;
          return row;
        });
      return axios.post(`/import/revenues`, importData).then((response) => {
        if (
          response.data.invalid.length === 0 &&
          Object.keys(response.data.error).length === 0
        ) {
          this.importSuccess = true;
        } else {
          this.notImported = response.data.error;
        }
      });
    },
    showErrors(errors)
    {
      this.hasError = errors.length > 0
      this.lineErrors = errors;
    }
  },
};
</script>

<style lang="scss">
.error-message {
  margin-top: 1.5rem;
  padding: 10px 2em;
}
</style>
